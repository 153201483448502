<template>
  <div class="theme-default">
    <v-system-message></v-system-message>
    <v-header></v-header>
    <v-main>
      <v-credits-alert></v-credits-alert>
      <v-fade-transition
        name="fade"
        mode="out-in"
        class="testing"
      >
        <router-view />
      </v-fade-transition>
    </v-main>
    <v-foot></v-foot>
  </div>
</template>

<script>
  export default {
    name: 'Theme.vue',
  };
</script>

<style lang="scss">
  .theme-default{
    .v-main{
      padding: 0 !important;
      .logo-wrapper{
        position: absolute;
        top: 30px;
        right: 40px;
      }
    }
  }
</style>